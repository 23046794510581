import { gql } from '~/__gql-generated__';
import { PostType } from '~/__gql-generated__/graphql';
import {
  cache,
  createAsync,
  useParams,
  useSearchParams,
  type RouteDefinition,
} from '@solidjs/router';
import { Match, Switch } from 'solid-js';

import CategoryHeader from '~/components/CategoryHeader';
import Post from '~/components/Post';
import PostList from '~/components/PostList';
import UserPage from '~/components/UserPage';
import NotFound from '~/routes/[...404]';
import { generateWidthThumbs } from '~/utils/generateThumbs';
import { client } from '~/utils/graphql';

enum SlugType {
  CATEGORY,
  POST,
  USER,
  NONE,
}

const SLUG_TYPE = gql(`
  query SlugType($slug: String!) {
    categories(where: { slug: $slug }, options: { limit: 1 }) {
      title
      body
      cover {
        url
      }
    }
    posts(
      where: { slug: $slug, status: PUBLISHED, type: PAGE, deletedAt: null }
      options: { limit: 1 }
    ) {
      id
    }
    users(where: { profile: { slug: $slug } }, options: { limit: 1 }) {
      id
    }
  }
`);

const getSlugType = cache(async (slug: string) => {
  'use server';

  const { data } = await client.query({
    query: SLUG_TYPE,
    variables: { slug },
  });

  if (data.categories.length > 0) {
    const category = {
      ...data.categories[0],
      coverSet: generateWidthThumbs(data.categories[0].cover.url, 1),
    };
    return [SlugType.CATEGORY, category] as const;
  }

  if (data.posts.length > 0) {
    return [SlugType.POST, undefined] as const;
  }

  if (data.users.length > 0) {
    return [SlugType.USER, data.users[0].id] as const;
  }

  return [SlugType.NONE, undefined] as const;
}, 'slugType');

export const route = {
  preload: ({ location }) => {
    const [, slug] = location.pathname.split('/');
    return getSlugType(slug);
  },
} satisfies RouteDefinition;

export default function Slug() {
  const parameters = useParams();
  const [query] = useSearchParams();
  const type = createAsync(() => getSlugType(parameters.slug));

  const category = () => {
    const stableType = type();
    if (stableType?.[0] !== SlugType.CATEGORY) {
      return;
    }

    return stableType[1];
  };

  const postId = () => {
    const stableType = type();
    if (stableType?.[0] !== SlugType.USER) {
      return;
    }

    return stableType[1];
  };

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const showBody = () => Number.parseInt(query.p?.toString() || '1') <= 1;

  return (
    <Switch fallback={<NotFound />}>
      <Match when={type()?.[0] === SlugType.CATEGORY}>
        <PostList filter={{ category: { slug: parameters.slug } }}>
          <CategoryHeader
            title={category()?.title ?? ''}
            body={showBody() ? category()?.body : undefined}
            cover={category()?.cover.url ?? ''}
            coverSet={category()?.coverSet ?? ''}
          />
        </PostList>
      </Match>
      <Match when={type()?.[0] === SlugType.POST}>
        <Post type={PostType.Page} />
      </Match>
      <Match when={type()?.[0] === SlugType.USER}>
        <UserPage id={postId() ?? ''} />
      </Match>
    </Switch>
  );
}
